import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import FeaturedImage from 'images/implant-in-lower-jawbone.jpg'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const FillingInTheGaps: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const root_canal_expect_img = data.root_canal_expect_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Filling in the Gaps About Cosmetic Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="If you have broken or damaged teeth consider a visit to The Dentists at Gateway Crossing for dental cosmetics and all things cosmetic dentistry." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="If you have broken or damaged teeth consider a visit to The Dentists at Gateway Crossing for dental cosmetics and all things cosmetic dentistry." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="If you have broken or damaged teeth consider a visit to The Dentists at Gateway Crossing for dental cosmetics and all things cosmetic dentistry." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
                <h1>
                    Filling in the Gaps About Cosmetic Dentistry
                </h1>
                <ul className="list-inline">
                    <li className="list-inline-item article-time">
                    2021/11/26
                    </li>
                    <li className="list-inline-item badge badge-primary">
                    Cosmetic Dentist
                    </li>
                    <li className="list-inline-item badge badge-secondary">
                    McCordsville
                    </li>
                </ul>
                <img src={FeaturedImage} className="blog-post-img"/>
                {/*<h2 className="mb-3">
                    Root canals are often needed when a cavity has been left untreated.
                </h2>*/}
                <h2 className="mb-3">
                    Dental Work Done Differently
                </h2>
                <p>
                If you have broken or damaged teeth, and are perhaps ashamed of your smile, maybe you should consider a visit to The Dentists at Gateway Crossing in McCordsville, IN, as they perform a lot of dental cosmetics and can give you a fabulous smile makeover.</p>
                <p>
                    We all know how detrimental stained, broken or yellow teeth can be to our self-image, and therefore, our self-esteem. There are ways for us to work on these things though.
                </p>
                <p>
                    If you are one of the many people who feel their teeth are letting them down, you could consider a trip to The Dentists at Gateway Crossing for porcelain veneers or implants and see how your smile can make you a whole lot happier!
                </p>
                <h3 className="mb-3">
                    Porcelain Veneers
                </h3>
                <p>
                    Veneers are one of the most popular ways to update your smile and get your teeth shiny and dazzling again.
                </p>
                <p>
                    These are made by initially making an impression of your teeth by taking a digital scan of your teeth which then help ensure the porcelain veneers are customized to each patient. With our McCordsville dentist porcelain veneers, we use high-tech artistry to give them exquisite natural detail.
                </p>
                <p>
                    You’ll be given temporary veneers between treatments, so you don’t have to worry about how you look in between the treatment sessions. Once these are removed, the custom veneers are bonded to your teeth’s surface for immediate, visible and fantastic improvement.<br />
                    The fantastic benefits of veneers include:
                </p>
                <ul>
                    <li>
                        Natural-looking, beautiful results made with high-tech artistry
                    </li>
                    <li>
                        Versatile, custom-made and easily adapted to each patient’s needs
                    </li>
                    <li>
                        Have a life expectancy of up to 15 years – porcelain is strong as well as being totally gorgeous
                    </li>
                    <li>
                        Robust, durable and stain-resistant.
                    </li>
                </ul>
                <p>
                    Patients who have moderate to severe yellowing or stains across the surface of their teeth are great candidates for these porcelain veneers as they repair and strengthen the surface.
                    If you have crooked, chipped teeth or uneven, worn down, or significant gaps between your teeth, veneers can also give you superb results.
                </p>
                <h3 className="mb-3">
                    Dental Implants
                </h3>
                <p>
                    However, some of us have teeth with extensive damage that may need more than just surface treatment, such as veneers. Some teeth may be so damaged they need to be replaced, which is where dental implants McCordsville, IN, come in.
                </p>
                <p>
                    By using implants that are made of titanium or zirconium, our implants can help repair your damaged teeth and restore your beautiful, naturally healthy smile.
                </p>
                <p>
                    The prosthetic tooth sits flush to the gum line, so no one will be able to spot which are your natural teeth and which are restorations. The great thing about implants is that they not only look like natural teeth, but they also behave like them too by providing a strength and function that is unrivalled by other teeth restorations – you can eat and drink anything just like you used to!
                </p>
                <p>
                    The incredible benefits of dental implants include:
                </p>
                <ul>
                    <li>
                        Replacement of damaged or broken teeth with natural-looking teeth
                    </li>
                    <li>
                        Improving functionality lost due to damage, as well as supporting your facial structure
                    </li>
                    <li>
                        Can improve bone loss
                    </li>
                    <li>
                        Improving your overall smile appearance and won’t limit what you can eat
                    </li>
                    <li>
                        It lasts an entire lifetime and is easy to care for.
                    </li>
                </ul>
                <p>
                    Make the most of your smile, and take a look at the great benefits of what cosmetic dentistry can offer you…
                </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default FillingInTheGaps

export const query = graphql`
  query FillingInTheGapsQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal_expect_img: file(name: { eq: "single-implant-lower-jawbone" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
